.fileList {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  gap: 5px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  background-color: #fff;
  min-height: 120px;
}
