.userTable {
  background-color: #ffffff;
  width: 100%;
  padding: 20px 20px 0 20px;
  border-radius: 15px;
  font-size: 16px;
  background-color: #1e1e1c;
}

th {
  cursor: pointer;
}

td {
  vertical-align: middle;
}
.userTable table tr {
  border-bottom-color: #cadee950;
  line-height: 2;
}

.userTable table tr:hover {
  border-bottom-color: #cadee950;
  line-height: 2;
}
.userTable table tr:last-child {
  border-color: transparent;
}
.userTable table thead tr:last-child {
  border-bottom-color: #cadee950;
}

.paginationContainer {
  display: flex !important;

  bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 15px !important;
  background-color: transparent;
  /* background-color: #adb6bb; */
  margin: 20px !important;
  border-radius: 10px;
  display: inline-block;
  padding: 0 10px;
}
.rc-pagination-options {
  display: none !important;
}

.paginationContainer li {
  margin: 0 2px;
  border: none;
  border-radius: 50%;
  background-color: #cadee9;
  width: 36px;
  height: 36px;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.paginationContainer li a {
  background-color: transparent;
}
.paginationContainer li:hover {
  color: #cba15e;
  background-color: #1e1e1c;
}

.paginationContainer li a:hover {
  color: #cba15e;
  background-color: transparent;
}
.paginationContainer li:hover a {
  color: #cba15e;
  background-color: transparent;
}
.paginationContainer li button {
  margin: 0 2px;
  border: none !important;
  border-radius: 50%;
  background-color: transparent !important;
  width: 36px;
  height: 36px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.searchInput {
  border: 2px solid #f3f3f3;
  background-color: white;
  border-radius: 8px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  width: 300px;
}

@media screen and (max-width: 950px) {
  .userTable {
    overflow-x: scroll;
  }
  .userTable table {
    width: 950px;
    font-size: 12px;
  }
  .paginationContainer li {
    font-size: 12px;
    width: 32px;
    height: 32px;
  }
}
@media screen and (max-width: 650px) {
  .userTable table {
    font-size: 12px;
  }
  .paginationContainer li {
    font-size: 11px;
    width: 27px;
    height: 27px;
  }
}
