.chatsList {
  width: 100%;
  height: 100%;
  background-color: #1e1e1c;

  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom: 1px solid #cadee935;
  overflow: auto;
}
.chatsList::-webkit-scrollbar {
  width: 8px;
}

.chatsList::-webkit-scrollbar-thumb {
  background-color: #cadee9;
  border-radius: 4px;
}

.chatsList::-webkit-scrollbar-track {
  border-radius: 4px;
}

.chatItem > div {
  background-color: #1e1e1c;
  color: #cadee9;
}
.chatItem {
  background-color: #1e1e1c;
  color: #cadee9;
}
.chatItem:hover {
  background-color: #1e1e1c;
  color: #cadee9;
}

.chatItem > div:hover {
  background-color: #6d6d6d;
  color: #a2b7c2;
}
.chatItem > div > div:hover {
  background-color: #6d6d6d;
  color: #a2b7c2;
}
.chatItem > div > div > div:hover {
  background-color: #6d6d6d;
  color: #a2b7c2;
}
.chatItem > div > div > div > div:hover {
  background-color: #6d6d6d;
  color: #a2b7c2;
}
.activeChat > div:hover {
  background-color: #815f28;
}
.activeChat > div > div:hover {
  background-color: #815f28;
}
.activeChat > div > div > div:hover {
  background-color: #815f28;
}
.activeChat > div > div > div > div:hover {
  background-color: #815f28;
}
.activeChat :hover {
  background-color: #815f28;
}
.activeChat > div {
  background-color: #cba15e50;
}
.actionMessage {
  color: #cba15e;
  display: flex;
  align-items: center;
}
.actionMessage div {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}
.actionMessage div::before {
  display: none;
}
.actionMessage div div svg {
  display: none;
}
.actionMessage div div div::after {
  display: none;
}
.paginationContainer {
  display: flex !important;
  bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  background-color: transparent;
  justify-content: center;

  border-radius: 10px;
  display: inline-block;
  padding: 0 0px;
  margin: 10px 10px !important;
}
.rc-pagination-options {
  display: none !important;
}

.paginationContainer li {
  margin: 0 2px;
  border: none;
  border-radius: 50%;
  background-color: #cadee9;
  width: 36px;
  height: 36px;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.paginationContainer li a {
  background-color: transparent;
}
.paginationContainer li:hover {
  color: #cba15e;
  background-color: #1e1e1c;
}

.paginationContainer li a:hover {
  color: #cba15e;
  background-color: transparent;
}
.paginationContainer li:hover a {
  color: #cba15e;
  background-color: transparent;
}
.paginationContainer li button {
  margin: 0 2px;
  border: none !important;
  border-radius: 50%;
  background-color: transparent !important;
  width: 36px;
  height: 36px;
  transition: color 0.3s ease, background-color 0.3s ease;
}
.wp {
  background-color: #1e1e1c;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: calc(40% - 2px);
  overflow: auto;
}
.sceletonDiv {
  width: calc(100% - 20px);
  max-width: 400px;
  margin-left: 10px;
}

.hide_list {
  display: flex;
}

@media screen and (max-width: 900px) {
  .wp {
    width: 100%;
  }
  .paginationContainer {
    font-size: 12px !important;
  }
  .paginationContainer li button {
    width: 20px !important;
    height: 20px !important;
  }
  .paginationContainer li {
    font-size: 12px;
    width: 32px;
    height: 32px;
  }
  .paginationContainer li {
    font-size: 12px;
    width: 32px;
    height: 32px;
  }
  .hide_list {
    display: none;
  }
}
@media screen and (max-width: 650px) {
  .paginationContainer li {
    font-size: 11px;
    width: 27px;
    height: 27px;
  }
}
