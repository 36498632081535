.managersInput {
  padding: 12px 20px;
  font-size: 16px;
  width: 420px;
  border: #cba15e solid 1px;
  border-radius: 15px;
  margin-bottom: 26px;
  transition: box-shadow 0.3s ease;
  line-height: 1;
  background-color: #1e1e1c;
  color: #ffffff;
}

.managersInput:hover {
  border: #cba15e solid 1px;
  box-shadow: 0px 0px 0px 2px #cba15e;
}
.managersInput:focus {
  border: #cba15e solid 1px;
  box-shadow: 0px 0px 0px 2px #cba15e;
  outline: none;
}
.passwordInput {
  margin-bottom: 0px;
}
.managersBtn {
  flex-grow: 1;
  color: #1e1e1c;
  background-color: #cba15e;
  border-radius: 15px;
  padding: 15.5px 19px;
  transition: background-color 0.3s ease;
  border: none;
  line-height: 1;
  width: fit-content;
}
.managersBtn:hover {
  background-color: #adb6bb;
}
.closeBtn {
  flex-grow: 1;
  color: transparent;
  background-color: #1e1e1c;
  color: #fff;
  border-radius: 15px;
  padding: 15.5px 19px;
  transition: background-color 0.3s ease;
  border: 1px solid #cba15e;
  line-height: 1;
  width: fit-content;
}
.closeBtn:hover {
  background-color: #adb6bb;
}
.btnDiv {
  display: flex;
  gap: 51px;
  justify-content: space-between;
}

.newManagerBtn {
  color: #1e1e1c;
  background-color: #cba15e;
  margin-top: 15px;
  transition: background-color 0.3s ease;
  font-size: 14px;
  padding: 14.5px 27px;
  border-radius: 15px;
  border: none;
}
.newManagerBtn:hover {
  color: #cba15e;
  background-color: #1e1e1c;
}
.userTable {
  background-color: #ffffff;
  width: 100%;
  padding: 20px 20px 0 20px;
  border-radius: 15px;
  font-size: 16px;
  background-color: #1e1e1c;
}

th {
  cursor: pointer;
}

td {
  vertical-align: middle;
}
.userTable table tr {
  border-bottom-color: #cadee950;
  line-height: 2;
}

.userTable table tr:hover {
  border-bottom-color: #cadee950;
  line-height: 2;
}
.userTable table tr:last-child {
  border-color: transparent;
}
.userTable table thead tr:last-child {
  border-bottom-color: #cadee950;
}
.modal {
  padding-left: 170px;
  padding-right: 170px;
  padding-top: 82px;
  padding-bottom: 66px;
  max-width: 760px;
}
.backdrop {
  backdrop-filter: blur(8px);
}
.backdrop > div {
  max-width: 760px;
}
.backdrop > div > div {
  background-color: #1e1e1c;
  border-radius: 15px;
}
.newManagerTitle {
  font-size: 24px;
  color: #fff;
  text-align: center;
  margin-bottom: 56px;
}
.paginationContainer {
  display: flex !important;

  bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  background-color: transparent;
  /* background-color: #adb6bb; */
  margin: 20px !important;
  border-radius: 10px;
  display: inline-block;
  padding: 0 10px;
}
.rc-pagination-options {
  display: none !important;
}

.paginationContainer li {
  margin: 0 2px;
  border: none;
  border-radius: 50%;
  background-color: #cadee9;
  width: 36px;
  height: 36px;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.paginationContainer li a {
  background-color: transparent;
}
.paginationContainer li:hover {
  color: #cba15e;
  background-color: #1e1e1c;
}

.paginationContainer li a:hover {
  color: #cba15e;
  background-color: transparent;
}
.paginationContainer li:hover a {
  color: #cba15e;
  background-color: transparent;
}
.paginationContainer li button {
  margin: 0 2px;
  border: none !important;
  border-radius: 50%;
  background-color: transparent !important;
  width: 36px;
  height: 36px;
  transition: color 0.3s ease, background-color 0.3s ease;
}
.passwordInputContainer {
  position: relative;
  display: inline-block;
  margin-bottom: 56px;
  width: 100%;
}
.showPasswordButton {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
}
.loginSvg {
  width: 24px;
}

@media screen and (max-width: 900px) {
  .modal {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
    max-width: 100%;
  }
  .modal h1 {
    font-size: 20px;
  }
  .modal input {
    width: 100%;
    font-size: 14px;
  }
  .modal .btnDiv {
    gap: 5%;
    font-size: 14px;
  }
  .backdrop div {
    max-width: auto;
  }
  .userTable {
    font-size: 14px;
  }
  .paginationContainer {
    font-size: 12px;
  }
  .paginationContainer ul li {
    width: 32px;
    height: 32px;
  }
}
