.startBtn {
  background-color: transparent;
  border: none;
  margin-left: 0;
  padding-left: 0;
}
.startIcon {
  width: 30px;
  height: 30px;
  fill: #cadee9;
}
.startIcon:active {
  fill: #cba15e;
}
.userRow:hover {
  color: #cba15e;
}
.centered {
  text-align: center;
}
.disInput {
  width: 400px;
  text-align: center;
  border: 1px solid #1e1e1c;
  padding: 3px 20px;
  margin-bottom: 5px;
  border-radius: 15px;
}
.backdrop {
  backdrop-filter: blur(8px);
}
.agree {
  border: none;
  border-radius: 15px;
  background-color: #e6a519;
  padding: 10px 20px;
}
.disagree {
  border: none;
  border-radius: 15px;
  background-color: #777777;
  padding: 10px 20px;
}

@media screen and (max-width: 650px) {
  .disInput {
    width: 100%;
    font-size: 12px;
    padding: 1.5% 0%;
  }
  .centered h6 {
    font-size: 14px;
  }
  .agree {
    font-size: 13px;
    flex: 1;
  }
  .disagree {
    font-size: 13px;
    flex: 1;
  }
}
