.usernameDiv {
  height: 100px;
  border-bottom: 1px solid #cadee935;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #cba15e;
}
.endBtn {
  background-color: #cba15e;
  color: #1e1e1c;
  border: none;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 20px;
  transition: background-color 0.3s ease;
  margin-left: auto;
  margin-right: 20px;
}
.endBtn:hover {
  background-color: #cadee9;
}
.endBtn:active {
  background-color: #cba15e;
  color: #1e1e1c;
}
.backdrop {
  backdrop-filter: blur(8px);
}
.agree {
  border: none;
  border-radius: 15px;
  background-color: #e6a519;
  padding: 10px 20px;
}
.disagree {
  border: none;
  border-radius: 15px;
  background-color: #777777;
  padding: 10px 20px;
}

.nameDiv {
  margin-left: 20px;
}
.usernameDivSkeleton {
  /* padding: 20px; */
  height: 100%;
}

.back_btn {
  color: #1e1e1c;
  width: fit-content;
  text-align: center;
  font-size: 14px;
  background-color: #cba15e;
  border-radius: 50px;
  padding: 1.5% 5%;
  margin-top: 2%;
  margin-bottom: 0;
  margin-left: 5%;
  cursor: pointer;
  display: none;
  transition: all 0.3s;
}
.back_btn:hover {
  opacity: 0.7;
}

@media screen and (max-width: 900px) {
  .usernameDiv {
    width: 100%;
    padding-left: 0;
    height: 70px;
    font-size: 14px;
    padding-left: 5%;
  }
  .endBtn {
    font-size: 14px;
    padding: 2%;
  }
  .nameDiv {
    margin-left: 10px;
    max-width: 25%;
  }
  .back_btn {
    display: block;
  }
}
@media screen and (max-width: 650px) {
  .usernameDiv {
    font-size: 12px;
  }
  .endBtn {
    font-size: 12px;
  }
  .back_btn {
    font-size: 12px;
  }
}
