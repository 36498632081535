.inputDiv {
  background-color: #fff;
  border-radius: 15px;
  margin: 10px;
  flex-grow: 1;
  display: flex;
  margin-top: 4px;
  height: 40px;
  text-align: center;
}
.btnDiv {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin-left: 4px;
  border-radius: 15px;
  margin-right: 5px;
  height: 100%;
}
.file_input_container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 27px;
  height: 100%;
}
.hidden_input {
  position: absolute;
  left: -9999px;
}
.custom_label {
  display: flex;
  height: 100%;
  border-radius: 15px;
  align-items: center;
  color: white;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease;
}

.custom_label:hover {
  background-color: #cadee9;
}
.fileSvg {
  margin: 5px;
}
.chatInput {
  height: 100%;
  border: none;
  resize: none;
  flex-grow: 1;
  border-radius: 15px;
  outline: none;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 5px;
}
.btn {
  border-radius: 15px;
  flex-grow: 1;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: none;
  transition: background-color 0.3s ease;
}
.btn:hover {
  background-color: #cadee9;
}

@media screen and (max-width: 900px) {
  .chatInput {
    font-size: 14px;
    padding-top: 10px;
  }
}
