.screen {
  width: 100vw;
  background-color: #1e1e1c;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginContainer {
  width: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(203, 161, 94, 0.33) 0%,
      rgba(203, 161, 94, 0) 100%
    ),
    #1e1e1c;
}
.loginDiv {
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 68px;
  padding-bottom: 56px;
  width: 468px;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
}
.loginTitle {
  margin-bottom: 56px;
  font-size: 24px;
  text-align: center;
  line-height: 1;
}
.loginInput {
  padding: 18.5px 20px;
  font-size: 16px;
  width: 100%;
  border: #cba15e solid 1px;
  margin-bottom: 56px;
  border-radius: 15px;
  transition: box-shadow 0.3s ease;
  line-height: 1;
}
.passwordInput {
  margin-bottom: 0;
}

.loginInput:hover {
  border: #cba15e solid 1px;
  box-shadow: 0px 0px 0px 2px #cba15e;
}
.loginInput:focus {
  border: #1e1e1c solid 1px;
  box-shadow: 0px 0px 0px 2px #1e1e1c;
  outline: none;
}
.loginBtn {
  background-color: #000;
  color: #fff;
  border-radius: 15px;
  padding: 15.5px 89px;
  transition: background-color 0.3s ease;
  border: none;
  line-height: 1;
}
.loginBtn:hover {
  background-color: #adb6bb;
}
.loginBtn:active {
  background-color: #cba15e;
}
.logo {
  position: absolute;
  top: -66px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  line-height: 1;
  color: #cba15e;
  font-family: Oswald;
  font-weight: 700;
  letter-spacing: 0.05em;
}
.passwordInputContainer {
  position: relative;
  display: inline-block;
  margin-bottom: 56px;
  width: 100%;
}

.showPasswordButton {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
}
.loginSvg {
  width: 24px;
}

@media screen and (max-width: 750px) {
  .loginDiv {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10%;
    padding-bottom: 10%;
    width: 90%;
  }
  .loginTitle {
    margin-bottom: 10%;
  }
  .loginInput {
    margin-bottom: 6%;
    padding: 4% 5%;
    width: 100%;
    font-size: 14px;
  }
  .passwordInputContainer {
    margin-bottom: 0%;
  }
  .passwordInputContainer input {
    margin-bottom: 0%;
  }
  .loginBtn {
    margin-top: 6%;
    font-size: 14px;
  }
  .loginTitle {
    font-size: 18px;
  }
}
