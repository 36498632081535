.container {
  height: calc(100vh - 141px);
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-right: 10px;
  gap: 10px;
}

.messages {
  width: calc(60% - 2px);
  display: flex;
  flex-direction: column;
  background-color: #1e1e1c;
  border-radius: 15px;
}

.messageList {
  background-color: #1e1e1c;
  overflow: auto;
  border-radius: 15px;
  padding: 10px;
  height: calc(100vh - 300px);
}

.messages > div > div::-webkit-scrollbar {
  width: 8px;
}

.messages > div > div::-webkit-scrollbar-thumb {
  background-color: #cadee9;
  border-radius: 4px;
}

.messages > div > div::-webkit-scrollbar-track {
  border-radius: 4px;
}

.chatTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 4px;
  padding-left: 20px;
}
.completeBtn {
  margin-right: 5px;
  border-radius: 15px;
  background-color: #151515;
  font-size: 18px;
  color: #f3f3f3;
}

.inputFile {
  border-radius: 25px;
  background-color: #fff;
}

.linkToFile {
  display: block;
  margin-bottom: 2px;
  color: #e6a519;
  background-color: #fff;
  padding: 3px;
  text-decoration: none;
  border-radius: 3px;
}
.divToFile {
  background-color: #fff;
  padding: 3px;
}

.contextMenu {
  background-color: #dbdbdb;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}
.menuItem:hover {
  background-color: rgb(192, 192, 192);
  cursor: pointer;
}
.menuItem {
  padding-left: 5px;
  padding-right: 5px;
}
.menuTitle {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  font-weight: bold;
}
.menuTitle:hover {
  cursor: default;
}

.selectFilter {
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 3px;
  border-radius: 8px;
}
.selectDiv {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.waiting {
  display: flex;
  justify-content: center;
  align-items: center;
}
.usernameSpan {
  color: #cba15e;
}

.messages div div div div {
  background-color: #cadee9;
}
.messages div div div div::after {
  display: none;
}
.messages div div div div svg {
  fill: #cadee9;
}

.filesSpan {
  margin-right: 3px;
  color: #1e1e1c;
}

.show_messages {
  opacity: 1 !important;
  pointer-events: auto !important;
  /* width: 95% !important; */
}

@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    height: 100%;
  }
  .messages {
    width: 90%;
    position: absolute;
    top: auto;
    left: auto;
    display: flex;
    opacity: 0;
    pointer-events: none;
  }
  .show_messages {
    width: 97% !important;
  }
}
@media screen and (max-width: 650px) {
  .paginationContainer li {
    font-size: 11px;
    width: 27px;
    height: 27px;
  }
  .show_messages {
    width: 95% !important;
  }
}
