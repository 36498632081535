* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

#root,
body,
html {
  width: 100%;
  box-sizing: border-box;
  background-color: #494947;
  overflow-x: hidden;
}
.app {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.rce-mbox-text {
  white-space: pre-line;
}
.rce-citem-body--bottom-title {
  color: #cadee9 !important;
}
.rce-citem-body--top-time {
  color: #cadee9 !important;
}
.rce-avatar-container {
  border-radius: 50%;
}
.rc-pagination-options {
  display: none !important;
}
.rc-pagination-next {
  display: none !important;
}
.rc-pagination-prev {
  display: none !important;
}
.rc-pagination-item-active {
  color: #cba15e !important;
  background-color: #1e1e1c !important;
  border: 1px solid #cba15e !important;
}
.rc-pagination-item-active a {
  color: #cba15e !important;
}
.Toastify__toast-body {
  white-space: pre-line;
}
.newDay {
  display: flex !important;
  align-items: center;
}
.newDay div {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}
.newDay > div > div {
  background-color: #cadee9 !important;
  color: #1e1e1c;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}
.newDay > div > div:last-child {
  display: none;
}
.newDay div::before {
  display: none;
}
.newDay div div svg {
  display: none;
}
.newDay div div div::after {
  display: none;
}

@media screen and (max-width: 900px) {
  .rce-avatar-container.xlarge {
    height: 35px !important;
    width: 35px !important;
  }
}
@media screen and (max-width: 950px) {
  .app {
    min-height: 100svh;
  }
}
