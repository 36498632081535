.startBtn {
  background-color: transparent;
  border: none;
  margin-left: 0;
  padding-left: 0;
}
.startIcon {
  width: 30px;
  height: 30px;
  fill: #cadee9;
}
.startIcon:active {
  fill: #cba15e;
}
.actionMessage {
  color: #cba15e;
  display: flex !important;
  align-items: center;
  width: 100%;
}
.actionMessage div {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}
.actionMessage div::before {
  display: none;
}
.actionMessage div div svg {
  display: none;
}
.actionMessage div div div::after {
  display: none;
}
.linkToFile {
  display: block;
  margin-bottom: 2px;
  color: #e6a519;
  background-color: #fff;
  padding: 3px;
  text-decoration: none;
  border-radius: 3px;
}
.divToFile {
  background-color: #fff;
  padding: 3px;
}
.message > div {
  background-color: #f3f3f3;
}
.message > div > div > svg {
  fill: #f3f3f3;
}
.backdrop {
  backdrop-filter: blur(8px);
}
.agree {
  border: none;
  border-radius: 15px;
  background-color: #e6a519;
  padding: 10px 20px;
}
.disagree {
  border: none;
  border-radius: 15px;
  background-color: #777777;
  padding: 10px 20px;
}
.messageList {
  height: 100%;
}
.modal {
  height: 70svh;
}
