.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
  margin-bottom: 15px;
}
.userCount {
  background-color: #1e1e1c;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 15px;
  text-align: center;
  color: #cba15e;
}
.containerForThreeRows {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
  .containerForThreeRows {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .containerForThreeRows div {
    font-size: 14px;
  }
  .container {
    gap: 1%;
    row-gap: 10px;
    grid-template-columns: 1fr;
  }
  .userCount h2 {
    font-size: 18px;
  }
}
