.root {
  width: 100vw;
  height: 80svh;
  z-index: 1010 !important;
  background-color: #494947;
  display: grid;
  place-items: center;
}

.root .svg {
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
