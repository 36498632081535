.navBar {
  display: flex;
  padding-left: 34px;
  padding-right: 34px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #1e1e1c;
  height: 100px;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 15px;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.navAvatar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navBtn {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 15px;
  background-color: transparent;
  color: #cba15e;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.exitBtn {
  border: none;
  background-color: #adb6bb;
  color: #1e1e1c;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 15px;
}

.navBtn:hover {
  background-color: #adb6bb;
  color: #1e1e1c;
}

.selectedPage {
  background-color: #cba15e;
  color: #1e1e1c;
}
.selectedPage:hover {
  background-color: #cba15e;
  color: #1e1e1c;
}
.rigthSide {
  display: flex;
  gap: 30px;
}
.rigthSide_mobile {
  display: none;
  align-items: center;
  justify-content: flex-end;
  gap: 2%;
  position: relative;
}
.select_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 110%;
  background-color: #1e1e1c;
  border-radius: 15px;
  border: 1px solid #adb6bb;
  z-index: 1005 !important;
}
.select_wrapper {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.show_select {
  display: flex;
}
.managerInfo {
  color: #cba15e;
}

.btn_withoutBorder {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

@media screen and (max-width: 1050px) {
  .rigthSide {
    display: none;
  }
  .rigthSide_mobile {
    display: flex;
  }
  .selectedPage {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .avatar {
    width: 35px;
    height: 35px;
  }
  .navAvatar span {
    font-size: 12px;
    max-width: 75%;
    text-align: center;
  }
  .navBar {
    padding: 0% 3%;
  }
  .navBtn {
    font-size: 12px;
    margin: 0;
  }
  .exitBtn {
    font-size: 12px;
  }
}
